// src/components/RoutesMap.js

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Палитра для раскраски различных маршрутов (Маршрут 1, Маршрут 2, ...)
// Можно расширять, если маршрутов будет очень много.
const COLORS = [
  '#e6194b','#3cb44b','#ffe119','#0082c8','#f58231',
  '#911eb4','#46f0f0','#f032e6','#d2f53c','#fabebe',
  '#008080','#e6beff','#aa6e28','#800000','#808000',
  '#ffd8b1','#000080','#808080','#808080','#000000'
];

/**
 * Извлекаем "число маршрута" из строки вида "Маршрут 1".
 * Возвращаем 1, если "Маршрут 1". Если не парсится, возвращаем null.
 */
function parseRouteNumber(routeName) {
  if(!routeName) return null;
  const num = parseInt(routeName.replace(/\D/g,''), 10);
  return isNaN(num) ? null : num;
}

/**
 * Получаем цвет для конкретного названия маршрута,
 * например "Маршрут 1" => COLORS[0], "Маршрут 2" => COLORS[1], ...
 */
function getColorForRoute(routeName) {
  const num = parseRouteNumber(routeName);
  if(!num) {
    return '#AAAAAA'; // для "Без маршрута" или нечисловых значений
  }
  return COLORS[(num - 1) % COLORS.length];
}

function RoutesMap() {
  const { routeSetId } = useParams();
  console.log('[RoutesMap] Параметры:', { routeSetId });

  // Вся информация о "наборе маршрутов" (RouteSet)
  const [routeSetDoc, setRouteSetDoc] = useState(null);
  // Массив строк, содержащих координаты и поля "Маршрут", "Порядок" и т.д.
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Фильтрация по "Маршрут"
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  /**
   * 1) Загружаем данные с сервера: /api/route-sets/:routeSetId
   */
  useEffect(() => {
    async function fetchRouteSet() {
      console.log('[fetchRouteSet] Старт запроса /api/route-sets/', routeSetId);
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`/api/route-sets/${routeSetId}`);
        console.log('[fetchRouteSet] Получен ответ:', response.data);
        setRouteSetDoc(response.data);
        setRows(response.data.routesData || []);
      } catch (err) {
        console.error('[fetchRouteSet] Ошибка:', err);
        setError('Не удалось загрузить набор маршрутов');
      } finally {
        setLoading(false);
      }
    }
    fetchRouteSet();
  }, [routeSetId]);

  /**
   * 2) Когда rows загружены, и они не пустые, инициализируем Яндекс.Карты
   */
  useEffect(() => {
    if(rows.length > 0) {
      console.log('[useEffect] Есть rows, грузим Yandex Maps...');
      loadYmaps()
        .then(() => {
          console.log('[useEffect] Yandex Maps загружен, вызываем ymaps.ready...');
          if(window.ymaps) {
            window.ymaps.ready(initMap);
          } else {
            console.error('[useEffect] window.ymaps не найден после загрузки');
            setError('Не удалось инициализировать карты');
          }
        })
        .catch(err => {
          console.error('[useEffect] Ошибка загрузки Яндекс.Карт:', err);
          setError('Ошибка загрузки Яндекс.Карт');
        });
    }
  }, [rows]);

  /**
   * Функция загрузки Yandex Maps через <script>.
   */
  function loadYmaps(){
    return new Promise((resolve, reject)=>{
      if(window.ymaps) {
        console.log('[loadYmaps] window.ymaps уже есть, OK');
        resolve();
        return;
      }
      console.log('[loadYmaps] Начинаем загрузку script Yandex Maps...');
      const script = document.createElement('script');
      script.src = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YANDEX_API_KEY}&lang=ru_RU`;
      script.async = true;
      script.onload = () => {
        console.log('[loadYmaps] Скрипт Yandex Maps загружен');
        resolve();
      };
      script.onerror = () => {
        console.error('[loadYmaps] Скрипт Yandex Maps не загрузился');
        reject(new Error('Ошибка загрузки Yandex Maps'));
      };
      document.head.appendChild(script);
    });
  }

  /**
   * Инициализация карты (аналог initMap в вашем PublicMap)
   */
  function initMap(){
    console.log('[initMap] Старт...');
    if(!mapContainerRef.current || !window.ymaps) {
      console.error('[initMap] Нет containerRef или ymaps');
      return;
    }
    // Москва по умолчанию
    let center = [55.751574, 37.573856];

    // Ищем первую валидную точку, чтобы установить центр
    const firstValid = rows.find(r => {
      const lat = parseFloat(r['Широта']);
      const lng = parseFloat(r['Долгота']);
      return !isNaN(lat) && !isNaN(lng);
    });
    if(firstValid){
      center = [
        parseFloat(firstValid['Широта']),
        parseFloat(firstValid['Долгота']),
      ];
      console.log('[initMap] Найдена первая валидная точка:', center);
    }

    // Создаём карту
    mapRef.current = new window.ymaps.Map(mapContainerRef.current, {
      center,
      zoom: 10,
      controls: ['zoomControl', 'fullscreenControl'],
    });
    console.log('[initMap] Карта создана:', mapRef.current);
    updateMapMarkers();
  }

  /**
   * 3) Фильтрация
   * Вычисляем статистику по "Маршрут" и автозаполняем selectedRoutes
   */
  const routeStats = useMemo(()=>{
    const stats = {};
    rows.forEach(r=>{
      const routeName = r['Маршрут'] || 'Без маршрута';
      if(!stats[routeName]) {
        stats[routeName] = { count:0, color:getColorForRoute(routeName) };
      }
      stats[routeName].count++;
    });
    return stats;
  }, [rows]);

  // Если routeStats поменялся, и selectedRoutes ещё не заданы, по умолчанию выбираем все
  useEffect(() => {
    if(rows.length>0 && Object.keys(routeStats).length>0 && selectedRoutes.length===0){
      setSelectedRoutes(Object.keys(routeStats));
    }
  }, [routeStats, rows, selectedRoutes.length]);

  /**
   * 4) Установка меток на карту с учётом фильтра selectedRoutes
   */
  function updateMapMarkers(){
    if(!mapRef.current||!window.ymaps) return;
    console.log('[updateMapMarkers] Фильтр по маршрутам:', selectedRoutes);
    mapRef.current.geoObjects.removeAll();

    let minLat = 90, maxLat=-90, minLng=180, maxLng=-180;
    const validPlacemarks=[];

    // Фильтруем строки по selectedRoutes
    const filteredRows = rows.filter(r=>{
      const routeName = r['Маршрут']||'Без маршрута';
      if(selectedRoutes.length===0) return true;
      return selectedRoutes.includes(routeName);
    });

    filteredRows.forEach((row, idx)=>{
      const lat = parseFloat(row['Широта']);
      const lng = parseFloat(row['Долгота']);
      if(isNaN(lat)||isNaN(lng)) {
        console.warn('[updateMapMarkers] Пропущены координаты строки', idx);
        return;
      }
      const routeName = row['Маршрут']||'Без маршрута';
      const color = getColorForRoute(routeName);

      // Сформируем balloonContent
      let balloon = '';
      balloon += `<div><b>Маршрут:</b> ${routeName}</div>`;
      if(row['Порядок']){
        balloon += `<div><b>Порядок:</b> ${row['Порядок']}</div>`;
      }
      if(row['Суммарная дистанция (м)']){
        balloon += `<div><b>Дистанция:</b> ${row['Суммарная дистанция (м)']} м</div>`;
      }
      if(row['Название']){
        balloon += `<div><b>Название:</b> ${row['Название']}</div>`;
      }

      const placemark = new window.ymaps.Placemark([lat,lng],{
        balloonContentBody: balloon,
      },{
        preset:'islands#circleIcon',
        iconColor: color,
      });
      mapRef.current.geoObjects.add(placemark);
      validPlacemarks.push(placemark);

      if(lat<minLat) minLat=lat;
      if(lat>maxLat) maxLat=lat;
      if(lng<minLng) minLng=lng;
      if(lng>maxLng) maxLng=lng;
    });

    if(validPlacemarks.length>1){
      mapRef.current.setBounds([[minLat, minLng],[maxLat, maxLng]], { checkZoomRange:true });
    } else if(validPlacemarks.length===1){
      mapRef.current.setZoom(14);
    } else {
      console.warn('[updateMapMarkers] Нет валидных точек для отображения');
    }
  }

  // Если меняется selectedRoutes => переставим метки
  useEffect(()=>{
    updateMapMarkers();
  }, [selectedRoutes]);

  if(error){
    return <div style={{color:'red', padding:'16px'}}>Ошибка: {error}</div>;
  }
  if(loading){
    return <div style={{padding:'16px'}}>Загрузка данных...</div>;
  }
  if(!routeSetDoc){
    return <div style={{padding:'16px'}}>Набор маршрутов не найден.</div>;
  }

  return (
    <div style={{ position:'relative', width:'100vw', height:'100vh' }}>
      {/* Шапка */}
      <header
        style={{
          padding:'8px 16px',
          background:'#fff',
          position:'absolute',
          top:0, left:0, right:0,
          zIndex:999,
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between',
          borderBottom:'1px solid #ccc'
        }}
      >
        <div style={{ display:'flex', alignItems:'center'}}>
          <h2 style={{margin:0}}>{routeSetDoc.name}</h2>
        </div>
        <div style={{textAlign:'right'}}>
          {routeSetDoc.sheetLink && (
            <p style={{ margin:0, fontSize:'0.9rem'}}>
              Источник: <a href={routeSetDoc.sheetLink} target="_blank" rel="noopener noreferrer">
                Google Sheets
              </a>
            </p>
          )}
          <p style={{ margin:0, fontSize:'0.9rem'}}>
            Всего объектов: {rows.length}
          </p>
        </div>
      </header>

      {/* Фильтр по маршрутам (справа) */}
      <div
        style={{
          position:'absolute',
          top:'60px',
          right:'16px',
          zIndex:1000,
          background:'#fff',
          padding:'8px',
          borderRadius:'4px',
          boxShadow:'0 2px 4px rgba(0,0,0,0.2)'
        }}
      >
        <strong>Фильтр по маршрутам:</strong>
        <ul style={{ listStyleType:'none', margin:0, padding:0 }}>
          {Object.keys(routeStats).map(routeName => (
            <li key={routeName}>
              <label style={{ display:'flex', alignItems:'center'}}>
                <input
                  type="checkbox"
                  checked={selectedRoutes.includes(routeName)}
                  onChange={(e)=>{
                    if(e.target.checked){
                      setSelectedRoutes(prev=> [...prev, routeName]);
                    } else {
                      setSelectedRoutes(prev=> prev.filter(rn=> rn!==routeName));
                    }
                  }}
                  style={{ marginRight:'8px'}}
                />
                <span
                  style={{
                    display:'inline-block',
                    width:12, height:12,
                    background: routeStats[routeName].color,
                    marginRight:'8px'
                  }}
                />
                {routeName} ({routeStats[routeName].count})
              </label>
            </li>
          ))}
        </ul>
      </div>

      {/* Статистика (слева) */}
      <div
        style={{
          position:'absolute',
          top:'60px',
          left:'16px',
          zIndex:1000,
          background:'#fff',
          padding:'8px',
          borderRadius:'4px',
          boxShadow:'0 2px 4px rgba(0,0,0,0.2)'
        }}
      >
        <strong>Статистика по маршрутам:</strong>
        <ul style={{ listStyleType:'none', margin:0, padding:0 }}>
          {Object.keys(routeStats).map(routeName=>(
            <li key={routeName}>
              <span
                style={{
                  display:'inline-block',
                  width:12, height:12,
                  background: routeStats[routeName].color,
                  marginRight:'8px'
                }}
              />
              {routeName}: {routeStats[routeName].count}
            </li>
          ))}
        </ul>
      </div>

      {/* Карта */}
      <div
        ref={mapContainerRef}
        style={{
          position:'absolute',
          top:60,
          bottom:0, left:0, right:0,
          width:'100%',
          height:'calc(100% - 60px)'
        }}
      />
    </div>
  );
}

export default RoutesMap;
