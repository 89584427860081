import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Admin from './components/Admin';
import Map from './components/Map';
import PrivateRoute from './components/PrivateRoute';
import PublicMap from './components/PublicMap';
import RoutesMap from './components/RoutesMap';
import { Box, CssBaseline } from '@mui/material';

function App() {
  return (
    <Router>
      <CssBaseline />
      <Box sx={{ width: '100vw', height: '100vh', m: 0, p: 0 }}>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin"
            element={
              <PrivateRoute adminOnly={true}>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route
            path="/map"
            element={
              <PrivateRoute>
                <Map />
              </PrivateRoute>
            }
          />
          <Route path="/maps/:mapId" element={<PublicMap />} />
	  <Route path="/routes-map/:routeSetId" element={<RoutesMap />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
