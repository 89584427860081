// src/components/PublicMap.js

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Фиксированные цвета для известных статусов
const FIXED_STATUS_COLORS = {
  "В работе": "green",
  "Не в работе": "red"
};

// Палитра для генерации цветов для остальных статусов (без зелёного и красного)
const RANDOM_COLORS = [
  "#3498db", // голубой
  "#9b59b6", // фиолетовый
  "#f1c40f", // жёлтый
  "#e67e22", // оранжевый
  "#1abc9c", // бирюзовый
  "#34495e", // темно-синий
  "#8e44ad"  // насыщенный фиолетовый
];

/**
 * Генерирует детерминированный «случайный» цвет для статуса,
 * используя хэширование названия статуса и палитру RANDOM_COLORS.
 */
function getRandomColorForStatus(status) {
  let hash = 0;
  for (let i = 0; i < status.length; i++) {
    hash = status.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % RANDOM_COLORS.length;
  return RANDOM_COLORS[index];
}

/**
 * Возвращает цвет для статуса:
 * - Если статус "В работе" или "Не в работе" — возвращает фиксированный цвет.
 * - Иначе — генерирует детерминированный цвет.
 */
function getColorForStatus(status) {
  if (FIXED_STATUS_COLORS[status]) {
    return FIXED_STATUS_COLORS[status];
  }
  return getRandomColorForStatus(status);
}

/**
 * Преобразуем обычную «шаринг»-ссылку Google Sheets в docId.
 */
function extractSheetId(shareLink) {
  console.log('[extractSheetId] Получена ссылка:', shareLink);
  const regex = /\/d\/([^/]+)\//;
  const match = shareLink.match(regex);
  if (!match) {
    console.warn('[extractSheetId] Не удалось извлечь ID из ссылки', shareLink);
    return null;
  }
  const docId = match[1];
  console.log('[extractSheetId] Извлечён docId:', docId);
  return docId;
}

/**
 * Вырезаем JSON из GViz-обёртки и парсим его.
 */
function parseGvizResponse(rawText) {
  console.log('[parseGvizResponse] Получен rawText:', rawText.substring(0, 100), '...');
  const startIndex = rawText.indexOf('{');
  const endIndex = rawText.lastIndexOf('}');
  if (startIndex === -1 || endIndex === -1) {
    console.error('[parseGvizResponse] Не найден JSON в GViz-ответе:', rawText);
    return null;
  }
  const jsonString = rawText.substring(startIndex, endIndex + 1);
  try {
    const parsed = JSON.parse(jsonString);
    console.log('[parseGvizResponse] Успешно распарсено:', parsed);
    return parsed;
  } catch (err) {
    console.error('[parseGvizResponse] Ошибка при JSON.parse GViz:', err);
    return null;
  }
}

/**
 * Преобразуем GViz-структуру (cols, rows) в массив объектов.
 *
 * Если заголовки в cols пустые, то первая строка данных используется как заголовки.
 * Выполняется trim() для заголовков и значений.
 */
function convertGvizTableToArray(table) {
  console.log('[convertGvizTableToArray] Преобразование таблицы:', table);
  if (!table || !table.cols || !table.rows) return [];

  let headers = table.cols.map(col => (col.label ? col.label.trim() : ''));
  console.log('[convertGvizTableToArray] Изначальные заголовки из cols:', headers);

  let dataRows = table.rows.slice();

  const allHeadersEmpty = headers.every(header => header === '');
  if (allHeadersEmpty && dataRows.length > 0) {
    const headerRow = dataRows.shift();
    headers = headerRow.c.map(cell => (cell && cell.v ? String(cell.v).trim() : ''));
    console.log('[convertGvizTableToArray] Заголовки получены из первой строки данных:', headers);
  }

  const result = [];
  dataRows.forEach((row, rIndex) => {
    const obj = {};
    headers.forEach((header, cIndex) => {
      const cell = row.c[cIndex];
      let value = cell ? cell.v : '';
      if (typeof value === 'string') {
        value = value.trim();
      }
      obj[header] = value;
    });
    result.push(obj);
  });

  console.log('[convertGvizTableToArray] Результат:', result);
  return result;
}

function PublicMap() {
  const { mapId } = useParams();
  console.log('[PublicMap] Параметры маршрута:', { mapId });

  const [mapData, setMapData] = useState(null);
  const [sheetRows, setSheetRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Состояние для фильтрации по статусам
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  // Загружаем данные карты с сервера
  useEffect(() => {
    const fetchMap = async () => {
      console.log('[fetchMap] Начало запроса карты с сервера...');
      try {
        setLoading(true);
        const res = await axios.get(`/api/maps/${mapId}`);
        console.log('[fetchMap] Получены данные карты:', res.data);
        setMapData(res.data);
      } catch (err) {
        console.error('[fetchMap] Ошибка при получении карты:', err);
        setError('Не удалось загрузить данные о карте');
      } finally {
        setLoading(false);
        console.log('[fetchMap] Завершение запроса карты');
      }
    };
    fetchMap();
  }, [mapId]);

  // Загружаем данные Google Sheets
  useEffect(() => {
    if (mapData && mapData.sheetLink) {
      console.log('[useEffect GViz] Имеется mapData с sheetLink:', mapData.sheetLink);
      const docId = extractSheetId(mapData.sheetLink);
      if (!docId) {
        console.warn('[useEffect GViz] Неверная ссылка на Google Sheets:', mapData.sheetLink);
        return;
      }
      const gvizUrl = `https://docs.google.com/spreadsheets/d/${docId}/gviz/tq?gid=0`;
      console.log('[useEffect GViz] Формируем URL для GViz:', gvizUrl);

      fetch(gvizUrl)
        .then((res) => {
          console.log('[useEffect GViz] Ответ от GViz получен, статус:', res.status);
          return res.text();
        })
        .then((rawText) => {
          console.log('[useEffect GViz] Текстовый ответ от GViz получен');
          const gviz = parseGvizResponse(rawText);
          if (!gviz || !gviz.table) {
            throw new Error('Некорректный формат GViz-данных');
          }
          const rows = convertGvizTableToArray(gviz.table);
          console.log('[useEffect GViz] Данные Google Sheets после преобразования:', rows);
          setSheetRows(rows);
        })
        .catch((err) => {
          console.error('[useEffect GViz] Ошибка при загрузке GViz:', err);
          setError('Не удалось загрузить данные из Google Sheets');
        });
    }
  }, [mapData]);

  // Вычисляем статистику по статусам и устанавливаем выбранные статусы по умолчанию (все)
  const statusStats = useMemo(() => {
    const stats = {};
    sheetRows.forEach(row => {
      const status = row['Статус'] && row['Статус'].trim() !== '' ? row['Статус'] : 'Не указан';
      const color = getColorForStatus(status);
      if (!stats[status]) {
        stats[status] = { count: 0, color };
      }
      stats[status].count += 1;
    });
    return stats;
  }, [sheetRows]);

  useEffect(() => {
    if (Object.keys(statusStats).length > 0) {
      // Если selectedStatuses пуст, показываем все объекты по умолчанию
      setSelectedStatuses(Object.keys(statusStats));
    }
  }, [statusStats]);

  // Загружаем Yandex Maps API
  useEffect(() => {
    if (sheetRows.length > 0) {
      console.log('[useEffect Yandex Maps] Есть данные sheetRows, загружаем Yandex Maps API...');
      loadYmaps()
        .then(() => {
          console.log('[useEffect Yandex Maps] Yandex Maps API загружен, вызываем ymaps.ready');
          if (window.ymaps) {
            window.ymaps.ready(initMap);
          } else {
            console.error('[useEffect Yandex Maps] Yandex Maps API не обнаружен после загрузки.');
            setError('Yandex Maps API не обнаружен после загрузки.');
          }
        })
        .catch((err) => {
          console.error('[useEffect Yandex Maps] Ошибка при загрузке Yandex Maps API:', err);
          setError('Не удалось загрузить Yandex Maps API.');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetRows]);

  /**
   * Функция загрузки Yandex Maps через <script>.
   */
  const loadYmaps = () => {
    return new Promise((resolve, reject) => {
      if (window.ymaps) {
        console.log('[loadYmaps] Yandex Maps API уже загружен.');
        resolve();
      } else {
        console.log('[loadYmaps] Начинаем загрузку Yandex Maps API...');
        const script = document.createElement('script');
        script.src = `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YANDEX_API_KEY}&lang=ru_RU`;
        script.async = true;
        script.onload = () => {
          console.log('[loadYmaps] Yandex Maps API успешно загружен.');
          resolve();
        };
        script.onerror = () => {
          console.error('[loadYmaps] Ошибка загрузки Yandex Maps API.');
          reject(new Error('Ошибка загрузки Yandex Maps API.'));
        };
        document.head.appendChild(script);
      }
    });
  };

  /**
   * Функция обновления меток на карте согласно выбранным статусам.
   * Если selectedStatuses пуст, используются все объекты.
   */
  const updateMapMarkers = () => {
    if (!mapRef.current || !window.ymaps) return;
    mapRef.current.geoObjects.removeAll();

    let minLat = Infinity,
      minLng = Infinity,
      maxLat = -Infinity,
      maxLng = -Infinity;
    const validPlacemarks = [];

    // Если фильтр пуст, используем все строки
    const filteredRows = sheetRows.filter(row => {
      const status = row['Статус'] && row['Статус'].trim() !== '' ? row['Статус'] : 'Не указан';
      if (selectedStatuses.length === 0) return true;
      return selectedStatuses.includes(status);
    });

    filteredRows.forEach((row, index) => {
      console.log(`[updateMapMarkers] Обработка строки ${index}:`, row);
      if (!row['Широта'] || !row['Долгота']) {
        console.warn(`[updateMapMarkers] Пропущена строка ${index}, отсутствуют координаты.`);
        return;
      }
      const lat = parseFloat(row['Широта']);
      const lng = parseFloat(row['Долгота']);
      if (isNaN(lat) || isNaN(lng)) {
        console.warn(`[updateMapMarkers] Пропущена строка ${index}, неверные координаты: lat=${lat}, lng=${lng}`);
        return;
      }
      const status = row['Статус'] && row['Статус'].trim() !== '' ? row['Статус'] : 'Не указан';
      const color = getColorForStatus(status);

      let preset;
      let presetOptions = {};
      if (status === "В работе") {
        preset = "islands#greenIcon";
      } else if (status === "Не в работе") {
        preset = "islands#redIcon";
      } else {
        preset = "islands#circleIcon";
        presetOptions = { iconColor: color };
      }

      const balloonContent = `
        <div><strong>Регион:</strong> ${row['Регион'] || ''}</div>
        <div><strong>Город:</strong> ${row['Город'] || ''}</div>
        <div><strong>Адрес:</strong> ${row['Адрес'] || ''}</div>
        <div><strong>Статус:</strong> ${status}</div>
      `;
      
      const placemark = new window.ymaps.Placemark(
        [lat, lng],
        {
          balloonContentHeader: row['Название'] || 'Объект',
          balloonContentBody: balloonContent,
        },
        {
          preset,
          ...presetOptions
        }
      );
      console.log(`[updateMapMarkers] Создан placemark для строки ${index}:`, placemark);
      validPlacemarks.push(placemark);
      mapRef.current.geoObjects.add(placemark);

      minLat = Math.min(minLat, lat);
      minLng = Math.min(minLng, lng);
      maxLat = Math.max(maxLat, lat);
      maxLng = Math.max(maxLng, lng);
    });

    if (validPlacemarks.length > 1) {
      const bounds = [
        [minLat, minLng],
        [maxLat, maxLng],
      ];
      console.log('[updateMapMarkers] Устанавливаем границы карты:', bounds);
      mapRef.current.setBounds(bounds, { checkZoomRange: true });
    } else if (validPlacemarks.length === 1) {
      console.log('[updateMapMarkers] Одна валидная точка найдена, устанавливаем зум 14.');
      mapRef.current.setZoom(14);
    } else {
      console.warn('[updateMapMarkers] Нет валидных точек для построения карты.');
    }
  };

  /**
   * Инициализация карты.
   */
  const initMap = () => {
    console.log('[initMap] Инициализация карты...');
    if (!mapContainerRef.current || !window.ymaps) {
      console.error('[initMap] Невозможно инициализировать карту — нет containerRef или ymaps.');
      return;
    }
    let center = [55.751574, 37.573856]; // Москва по умолчанию
    console.log('[initMap] Центр карты по умолчанию (Москва):', center);
    const firstValidRow = sheetRows.find(row => {
      if (!row['Широта'] || !row['Долгота']) return false;
      const lat = parseFloat(row['Широта']);
      const lng = parseFloat(row['Долгота']);
      return !isNaN(lat) && !isNaN(lng);
    });
    if (firstValidRow) {
      center = [
        parseFloat(firstValidRow['Широта']),
        parseFloat(firstValidRow['Долгота'])
      ];
      console.log('[initMap] Найдена первая валидная точка, новый центр карты:', center);
    }
    mapRef.current = new window.ymaps.Map(mapContainerRef.current, {
      center,
      zoom: 10,
      controls: ['zoomControl', 'fullscreenControl']
    });
    console.log('[initMap] Карта создана:', mapRef.current);
    updateMapMarkers();
  };

  // Обновление меток при изменении выбранных статусов
  useEffect(() => {
    if (mapRef.current && window.ymaps) {
      console.log('[useEffect filter] Обновление меток при изменении фильтра:', selectedStatuses);
      updateMapMarkers();
    }
  }, [selectedStatuses]);

  if (loading) {
    console.log('[PublicMap] Статус: Загрузка данных...');
    return <div>Загрузка данных...</div>;
  }
  if (error) {
    console.log('[PublicMap] Ошибка:', error);
    return <div style={{ color: 'red' }}>{error}</div>;
  }
  if (!mapData) {
    console.log('[PublicMap] Карта не найдена.');
    return <div>Карта не найдена.</div>;
  }

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      {/* Шапка */}
      <header
        style={{
          padding: '8px 16px',
          backgroundColor: '#fff',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 999,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #ccc'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="../Images/Logo.jpg" alt="Logo" style={{ width: 40, height: 40, marginRight: 10 }} />
          <h2 style={{ margin: 0 }}>{mapData.name}</h2>
        </div>
        <div style={{ textAlign: 'right' }}>
          <p style={{ margin: 0, fontSize: '0.9rem' }}>
            Источник:{' '}
            <a href={mapData.sheetLink} target="_blank" rel="noopener noreferrer">
              Google Sheets
            </a>
          </p>
          <p style={{ margin: 0, fontSize: '0.9rem' }}>Всего объектов: {sheetRows.length}</p>
        </div>
      </header>

      {/* Фильтр по статусам */}
      <div
        style={{
          position: 'absolute',
          top: '60px',
          right: '16px',
          zIndex: 1000,
          background: 'white',
          padding: '8px',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
        }}
      >
        <strong>Фильтр по статусам:</strong>
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {Object.keys(statusStats).map(status => (
            <li key={status}>
              <label style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  checked={selectedStatuses.includes(status)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedStatuses(prev => [...prev, status]);
                    } else {
                      setSelectedStatuses(prev => prev.filter(s => s !== status));
                    }
                  }}
                  style={{ marginRight: '8px' }}
                />
                <span
                  style={{
                    display: 'inline-block',
                    width: 12,
                    height: 12,
                    background: statusStats[status].color,
                    marginRight: '8px'
                  }}
                ></span>
                {status} ({statusStats[status].count})
              </label>
            </li>
          ))}
        </ul>
      </div>

      {/* Дополнительная статистика по статусам */}
      <div
        style={{
          position: 'absolute',
          top: '60px',
          left: '16px',
          zIndex: 1000,
          background: 'white',
          padding: '8px',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
        }}
      >
        <strong>Статистика по статусам:</strong>
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {Object.keys(statusStats).map(status => (
            <li key={status}>
              <span
                style={{
                  display: 'inline-block',
                  width: 12,
                  height: 12,
                  background: statusStats[status].color,
                  marginRight: '8px'
                }}
              ></span>
              {status}: {statusStats[status].count}
            </li>
          ))}
        </ul>
      </div>

      {/* Контейнер для карты - растянут на весь экран */}
      <div
        ref={mapContainerRef}
        style={{
          position: 'absolute',
          top: 60,
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%',
          height: 'calc(100% - 60px)'
        }}
      ></div>
    </div>
  );
}

export default PublicMap;
