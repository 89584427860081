// MapPage.js

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Drawer,
  Typography,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Chip,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Switch,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Grid,
  Paper,
  Tooltip,
  Divider,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FilterList as FilterListIcon,
  ClearAll as ClearAllIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// Стиль для Paper с тенями и скруглёнными углами
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
}));

const MapPage = () => {
  // --------------------------
  //     Состояния и рефы
  // --------------------------
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [stats, setStats] = useState({ total: 0, inProgress: 0 });
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const [projects, setProjects] = useState([]);
  const [boards, setBoards] = useState({});
  const [columns, setColumns] = useState({});
  const [tasks, setTasks] = useState([]); // Все задачи
  const [filteredData, setFilteredData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  // Фильтры
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState({});
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedColors, setSelectedColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);

  // Поиск (для Autocomplete)
  const [searchQuery, setSearchQuery] = useState('');
  const [searchOptions, setSearchOptions] = useState([]); // список уникальных title

  // Тема
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(false);

  // Карта
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const clustererRef = useRef(null);
  const yandexApiKey = process.env.REACT_APP_YANDEX_API_KEY;
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const theme = createTheme({ palette: { mode: darkMode ? 'dark' : 'light' } });

  // Админ
  const [isAdmin, setIsAdmin] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  // Кластер
  const [selectedClusterObjects, setSelectedClusterObjects] = useState([]);
  const [clusterListOpen, setClusterListOpen] = useState(false);

  // --------------------------
  //     Вспомогательные функции
  // --------------------------

  // ФАЗЗИ ПОИСК (нечёткий) — поиск нескольких слов в тексте
  const fuzzyMatch = (text = '', query = '') => {
    const normalize = (str) =>
      str
        .toLowerCase()
        .replace(/[.,]/g, '') // убираем точки/запятые (при необходимости расширьте)
        .replace(/\s+/g, ' ')
        .trim();

    const normalizedText = normalize(text);
    const normalizedQuery = normalize(query);

    if (!normalizedQuery) return true; // если пустой запрос — всё подходит

    const words = normalizedQuery.split(' '); // разбиваем запрос на слова
    return words.every((word) => normalizedText.includes(word));
  };

  // Проверка статуса администратора
  const checkAdminStatus = async () => {
    try {
      const response = await axios.get('/api/auth/check-auth', {
        withCredentials: true,
      });
      setIsAdmin(response.data.isAdmin);
      console.log('isAdmin:', response.data.isAdmin);
    } catch (error) {
      console.error('Ошибка проверки статуса администратора:', error);
    } finally {
      setAuthChecked(true);
    }
  };

  // Загрузка Yandex Maps
  const loadYmaps = () => {
    return new Promise((resolve, reject) => {
      if (window.ymaps) {
        console.log('Yandex Maps API уже загружен.');
        resolve();
      } else {
        console.log('Загрузка Yandex Maps API...');
        const script = document.createElement('script');
        script.src = `https://api-maps.yandex.ru/2.1/?apikey=${yandexApiKey}&lang=ru_RU`;
        script.async = true;
        script.onload = () => {
          console.log('Yandex Maps API загружен.');
          resolve();
        };
        script.onerror = () => {
          console.error('Не удалось загрузить Yandex Maps API.');
          reject(new Error('Yandex Maps API failed to load.'));
        };
        document.head.appendChild(script);
      }
    });
  };

  // Добавление маркеров в пакетах
  const addPlacemarksInBatches = (data, clusterer, map, batchSize = 500) => {
    let index = 0;

    const addBatch = () => {
      const batch = data.slice(index, index + batchSize);
      const placemarks = batch
        .filter((item) => item.lat && item.lng)
        .map((item) => {
          const placemark = new window.ymaps.Placemark(
            [item.lat, item.lng],
            {
              // То, что будет видно внутри балуна кластера
              clusterCaption: item.title,
              balloonContent: `${item.projectTitle} / ${item.columnTitle} / ${item.title}`,
              data: item,
            },
            {
              preset: 'islands#icon',
              iconColor: item.color || '#FF0000',
            }
          );

          // Обработчик клика для открытия балуна
          placemark.events.add('click', () => {
            placemark.balloon.open();
          });

          return placemark;
        });

      clusterer.add(placemarks);

      index += batchSize;

      if (index < data.length) {
        window.requestAnimationFrame(addBatch);
      } else {
        console.log('Все маркеры добавлены:', data.length);
        const bounds = clusterer.getBounds();
        if (bounds && bounds.length > 0) {
          map.setBounds(bounds, { checkZoomRange: true });
          console.log('Границы карты обновлены.');
        } else {
          console.warn(
            'Не удалось получить границы кластеризатора. Устанавливаем центр вручную.'
          );
          map.setCenter([55.751574, 37.573856], 5);
        }
        setLoading(false);
      }
    };

    addBatch();
  };

  // Обновление маркеров в кластеризаторе
  const updatePlacemarks = (data) => {
    if (!clustererRef.current || !mapRef.current) return;
    clustererRef.current.removeAll();
    addPlacemarksInBatches(data, clustererRef.current, mapRef.current, 500);
  };

  // Инициализация карты
  const initMap = (data) => {
    console.log('initMap вызван с данными:', data.length, 'объектов');

    if (!window.ymaps) {
      console.error('ymaps не определен. Проверьте загрузку Yandex Maps API.');
      setError('Не удалось инициализировать карту. API Yandex Maps не загружен.');
      setNotification({
        open: true,
        message: 'Не удалось загрузить карту. Попробуйте позже.',
        severity: 'error',
      });
      return;
    }

    if (!mapContainerRef.current) {
      console.error('Контейнер карты не найден.');
      setError('Не удалось найти контейнер для карты.');
      setNotification({
        open: true,
        message: 'Не удалось найти контейнер для карты.',
        severity: 'error',
      });
      return;
    }

    try {
      if (!mapInitialized) {
        console.log('Инициализация карты...');

        const centerOfMap = [55.751574, 37.573856]; // Москва
        const map = new window.ymaps.Map(mapContainerRef.current, {
          center: centerOfMap,
          zoom: 5,
          controls: ['zoomControl', 'searchControl', 'typeSelector', 'fullscreenControl'],
        });
        mapRef.current = map;
        console.log('Карта создана:', map);

        // Создание кластеризатора
        const clusterer = new window.ymaps.Clusterer({
          preset: 'islands#invertedVioletClusterIcons',
          groupByCoordinates: false,
          clusterDisableClickZoom: true,
          geoObjectOpenBalloonOnClick: true,
          clusterBalloonContentLayout: 'cluster#balloonAccordion',
          clusterBalloonItemContentLayout:
            window.ymaps.templateLayoutFactory.createClass(
              '<div>' +
                '<b>{{ properties.clusterCaption|raw }}</b>' +
                '<br/> {{ properties.balloonContent|raw }}' +
              '</div>'
            ),
          clusterOpenBalloonOnClick: true,
          clusterBalloonAccordionShowIcons: true,
        });
        clustererRef.current = clusterer;
        map.geoObjects.add(clusterer);
        console.log('Кластеризатор добавлен на карту:', clusterer);

        // Добавление маркеров в пакетах
        addPlacemarksInBatches(data, clusterer, map, 500);

        // Обработчик клика на кластер
        clusterer.events.add('click', (e) => {
          const target = e.get('target');
          if (target && target instanceof window.ymaps.Cluster) {
            const geoObjects = target.getGeoObjects();
            const clusterData = geoObjects.map((obj) =>
              obj.properties.get('data')
            );
            setSelectedClusterObjects(clusterData);
            setClusterListOpen(true);
            console.log('Кластер содержит объектов:', clusterData.length);
          }
        });

        setMapInitialized(true);
        console.log('Карта инициализирована с новыми данными.');
        calculateStats(data);
      } else {
        console.log('Карта уже инициализирована. Обновление маркеров...');
        updatePlacemarks(data);
        calculateStats(data);
      }
    } catch (error) {
      console.error('Ошибка при инициализации карты:', error);
      setError('Произошла ошибка при инициализации карты.');
      setNotification({
        open: true,
        message: 'Произошла ошибка при инициализации карты.',
        severity: 'error',
      });
    }
  };

  // Загрузка данных и инициализация карты
  const fetchAllDataAndInitMap = async () => {
    setLoading(true);
    try {
      console.log('Загрузка всех данных...');

      // Загрузка проектов
      const projectsResponse = await axios.get('/api/projects');
      const projectsData = projectsResponse.data;
      setProjects(projectsData);

      // Доски
      const boardsPromises = projectsData.map((project) =>
        axios.get('/api/boards', { params: { projectId: project.id } })
      );
      const boardsResponses = await Promise.all(boardsPromises);
      const boardsData = {};
      projectsData.forEach((project, index) => {
        boardsData[project.id] = boardsResponses[index].data;
      });
      setBoards(boardsData);

      // Колонки
      const columnsPromises = [];
      for (const projectId in boardsData) {
        boardsData[projectId].forEach((board) => {
          columnsPromises.push(
            axios.get('/api/columns', { params: { boardId: board.id } })
          );
        });
      }
      const columnsResponses = await Promise.all(columnsPromises);
      const columnsData = {};
      let responseIndex = 0;
      for (const projectId in boardsData) {
        boardsData[projectId].forEach((board) => {
          columnsData[board.id] = columnsResponses[responseIndex].data;
          responseIndex++;
        });
      }
      setColumns(columnsData);

      // Задачи
      const tasksResponse = await axios.get('/api/tasks');
      const tasksData = tasksResponse.data;

      // "Обогащаем" задачи названиями проектов, колонок и т.д., а также добавляем boardId
      const projectsMap = {};
      projectsData.forEach((project) => {
        projectsMap[project.id] = project;
      });
      const columnsMap = {};
      Object.values(columnsData)
        .flat()
        .forEach((column) => {
          columnsMap[column.id] = column;
        });

      // **Добавляем карту columnId → boardId**
      const boardIdForColumnMap = {};
      for (const boardId in columnsData) {
        columnsData[boardId].forEach((column) => {
          boardIdForColumnMap[column.id] = boardId; // boardId остаётся строкой UUID
        });
      }

      const enrichedTasks = tasksData.map((task) => {
        const boardId = boardIdForColumnMap[task.columnId];
        return {
          ...task,
          boardId: boardId || null, // Добавляем boardId, если он существует
          projectTitle: projectsMap[task.projectId]?.title || '',
          columnTitle: columnsMap[task.columnId]?.title || '',
        };
      });

      setTasks(enrichedTasks);
      setFilteredData(enrichedTasks);
      setDisplayedData(enrichedTasks);

      // Цвета
      const uniqueColors = [
        ...new Set(enrichedTasks.map((item) => item.color).filter(Boolean)),
      ];
      setAvailableColors(uniqueColors);

      // Список уникальных title — для начальных подсказок в <Autocomplete>
      const uniqueTitles = [
        ...new Set(enrichedTasks.map((item) => item.title).filter(Boolean)),
      ];
      setSearchOptions(uniqueTitles);

      // Инициализация карты
      console.log('Yandex Maps API Key:', yandexApiKey);
      await loadYmaps();
      if (window.ymaps && mapContainerRef.current) {
        window.ymaps.ready(() => {
          console.log('ymaps.ready вызван');
          initMap(enrichedTasks);
        });
      } else {
        throw new Error(
          'ymaps или mapContainerRef.current недоступны после загрузки API.'
        );
      }

      // Проверка статуса администратора
      await checkAdminStatus();
    } catch (error) {
      console.error(
        'Ошибка при загрузке данных или инициализации карты:',
        error
      );
      setError('Произошла ошибка при загрузке данных или инициализации карты.');
      setNotification({
        open: true,
        message: 'Произошла ошибка при загрузке данных.',
        severity: 'error',
      });
      setLoading(false);
    }
  };

  // --------------------------
  //       useEffect
  // --------------------------
  useEffect(() => {
    fetchAllDataAndInitMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Обновляем карту, когда меняется displayedData
  useEffect(() => {
    if (mapInitialized) {
      console.log('Карта инициализирована. Обновление маркеров...');
      initMap(displayedData);
      if (displayedData.length === 0) {
        console.log('Нет данных для отображения на карте.');
        setNotification({
          open: true,
          message: 'Нет данных для отображения на карте.',
          severity: 'info',
        });
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedData]);

  // Таймер для "глобальной" фильтрации displayedData (если нужно)
  // (Если вы решите полностью перейти на Autocomplete.filterOptions, 
  //  можно убрать или изменить эту логику.)
  useEffect(() => {
    const timer = setTimeout(() => {
      const filtered = filteredData.filter(
        (task) =>
          searchQuery.trim() === '' ||
          fuzzyMatch(task.title, searchQuery.trim())
      );
      setDisplayedData(filtered);
    }, 500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, filteredData]);

  // Загрузка маркеров с учётом фильтров
  const loadMarkersWithFilters = () => {
    console.log('Загрузка маркеров с учётом фильтров...');
    setLoading(true);

    const selectedProjectIds = new Set(selectedProjects);
    const selectedBoardIds = new Set(Object.values(selectedBoards).flat());
    const selectedColumnIds = new Set(Object.values(selectedColumns).flat());

    console.log('Выбранные проекты:', Array.from(selectedProjectIds));
    console.log('Выбранные доски:', Array.from(selectedBoardIds));
    console.log('Выбранные колонки:', Array.from(selectedColumnIds));
    console.log('Выбранные цвета:', selectedColors);
    console.log('Поисковый запрос:', searchQuery);

    const uniqueTasks = [];
    const taskIds = new Set();

    // Для отладки: счетчики соответствий
    let projectMatches = 0;
    let boardMatches = 0;
    let columnMatches = 0;

    tasks.forEach((task) => {
      const matchesProject =
        selectedProjectIds.size > 0 && selectedProjectIds.has(task.projectId);
      const matchesBoard =
        selectedBoardIds.size > 0 && selectedBoardIds.has(task.boardId);
      const matchesColumn =
        selectedColumnIds.size > 0 && selectedColumnIds.has(task.columnId);
      const matchesColor =
        selectedColors.length === 0 || selectedColors.includes(task.color);

      // Для отладки
      if (matchesProject) projectMatches++;
      if (matchesBoard) boardMatches++;
      if (matchesColumn) columnMatches++;

      const anyFilterSet =
        selectedProjectIds.size > 0 ||
        selectedBoardIds.size > 0 ||
        selectedColumnIds.size > 0;

      const matchesAnyFilter = matchesProject || matchesBoard || matchesColumn;

      // Собираем все задачи, которые соответствуют хотя бы одному из фильтров
      // При этом цвет опционален
      if ((!anyFilterSet || matchesAnyFilter) && matchesColor) {
        if (!taskIds.has(task.id)) {
          uniqueTasks.push(task);
          taskIds.add(task.id);
        }
      }
    });

    // Отладочные логи
    console.log(`Проектных совпадений: ${projectMatches}`);
    console.log(`Досочных совпадений: ${boardMatches}`);
    console.log(`Колоночных совпадений: ${columnMatches}`);
    console.log('Всего отфильтрованных задач:', uniqueTasks.length);

    setFilteredData(uniqueTasks);
    console.log('Всего отфильтрованных задач:', uniqueTasks.length);

    // Обновляем варианты поиска (при желании)
    const uniqueTitles = [
      ...new Set(uniqueTasks.map((item) => item.title).filter(Boolean)),
    ];
    setSearchOptions(uniqueTitles);

    // Применяем fuzzyMatch к uniqueTasks сразу
    const displayedTasks = uniqueTasks.filter(
      (task) =>
        searchQuery.trim() === '' || fuzzyMatch(task.title, searchQuery.trim())
    );

    setDisplayedData(displayedTasks);
    console.log('Всего задач после поиска:', displayedTasks.length);

    setNotification({
      open: true,
      message: `Найдено ${displayedTasks.length} задач.`,
      severity: 'success',
    });
    setLoading(false);
  };

  // Статистика
  const calculateStats = (tasks) => {
    console.log('Расчёт статистики для задач:', tasks.length);
    const total = tasks.length;
    const inProgress = tasks.filter(
      (task) => task.columnTitle === 'В РАБОТУ'
    ).length;
    setStats({ total, inProgress });
    console.log('Статистика обновлена:', { total, inProgress });
  };

  // Выбор всех
  const handleSelectAll = () => {
    console.log('Выбор всех проектов, досок, колонок и цветов.');
    const allProjectIds = projects.map((p) => p.id);
    setSelectedProjects(allProjectIds);

    const allBoards = {};
    for (const projectId in boards) {
      allBoards[projectId] = (boards[projectId] || []).map((b) => b.id);
    }
    setSelectedBoards(allBoards);

    const allColumns = {};
    for (const boardId in columns) {
      allColumns[boardId] = (columns[boardId] || []).map((c) => c.id);
    }
    setSelectedColumns(allColumns);

    setSelectedColors(availableColors);
  };

  // Снять выбор со всего
  const handleDeselectAll = () => {
    console.log('Снятие выбора со всех проектов, досок, колонок и цветов.');
    setSelectedProjects([]);

    const emptyBoards = {};
    for (const projectId in boards) {
      emptyBoards[projectId] = [];
    }
    setSelectedBoards(emptyBoards);

    const emptyColumns = {};
    for (const boardId in columns) {
      emptyColumns[boardId] = [];
    }
    setSelectedColumns(emptyColumns);

    setSelectedColors([]);
  };

  // Чекбоксы проектов
  const handleProjectCheckboxChange = (e, projectId) => {
    if (e.target.checked) {
      setSelectedProjects((prev) => [...prev, projectId]);
    } else {
      setSelectedProjects((prev) => prev.filter((id) => id !== projectId));
    }
  };

  // Закрытие уведомлений
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  // Переключатель темы
  const handleToggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  // Выход
  const handleLogout = async () => {
    try {
      await axios.post('/api/auth/logout', {}, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      console.error('Ошибка выхода:', error);
      setError('Ошибка выхода. Пожалуйста, попробуйте снова.');
    }
  };

  // Переход в админку
  const goToAdminPanel = () => {
    navigate('/admin');
  };

  // Состояние «Выбрать все» и «неполный выбор»
  const isAllSelected =
    selectedProjects.length === projects.length &&
    Object.keys(boards).every(
      (projectId) =>
        selectedBoards[projectId]?.length === (boards[projectId]?.length || 0)
    ) &&
    Object.keys(columns).every(
      (boardId) =>
        selectedColumns[boardId]?.length === (columns[boardId]?.length || 0)
    ) &&
    selectedColors.length === availableColors.length;

  const isIndeterminate =
    (selectedProjects.length > 0 &&
      selectedProjects.length < projects.length) ||
    Object.keys(boards).some(
      (projectId) =>
        (selectedBoards[projectId]?.length || 0) > 0 &&
        (selectedBoards[projectId]?.length || 0) <
          (boards[projectId]?.length || 0)
    ) ||
    Object.keys(columns).some(
      (boardId) =>
        (selectedColumns[boardId]?.length || 0) > 0 &&
        (selectedColumns[boardId]?.length || 0) <
          (columns[boardId]?.length || 0)
    ) ||
    (selectedColors.length > 0 &&
      selectedColors.length < availableColors.length);

  // --------------------------
  //       Рендер компонента
  // --------------------------
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100vh', display: 'flex', width: '100vw' }}>
        {/* Поисковая строка посередине сверху */}
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: isMobile ? '80%' : '40%',
          }}
        >
          <Autocomplete
            freeSolo
            // Все изначальные варианты — это ваши уникальные title
            // (если ничего не введено).
            options={searchOptions}
            // Переопределяем, как именно фильтровать опции
            // Внутри фильтруем не сам options[], а весь массив tasks
            filterOptions={(options, state) => {
              const inputValue = state.inputValue.trim();
              // Если нет ввода, показываем все уникальные title (как раньше)
              if (!inputValue) {
                return options;
              }
              // Иначе прогоняем все задачи через fuzzyMatch
              const matchedTasks = tasks.filter((task) =>
                fuzzyMatch(task.title, inputValue)
              );
              // Берём все title совпавших задач
              const matchedTitles = matchedTasks.map((t) => t.title).filter(Boolean);
              // Убираем дубликаты
              return [...new Set(matchedTitles)];
            }}
            inputValue={searchQuery}
            onInputChange={(event, newInputValue) => {
              setSearchQuery(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Поиск по названию"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <SearchIcon color="action" style={{ marginRight: 8 }} />
                  ),
                  endAdornment: searchQuery && (
                    <IconButton
                      onClick={() => {
                        setSearchQuery('');
                      }}
                      aria-label="Очистить"
                    >
                      <ClearAllIcon />
                    </IconButton>
                  ),
                }}
              />
            )}
          />
        </Box>

        {/* Нижняя панель (Фильтры, Выход, Админ, Переключатель темы) */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Открыть панель фильтров">
            <Button
              onClick={() => setDrawerOpen(true)}
              className="filter-panel"
              variant="contained"
              startIcon={<FilterListIcon />}
              sx={{
                textTransform: 'none',
                mb: 2,
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
            >
              Фильтры
            </Button>
          </Tooltip>
          <Tooltip title="Выйти из аккаунта">
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogout}
              sx={{
                mb: 2,
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
            >
              Выйти
            </Button>
          </Tooltip>
          {authChecked && isAdmin && (
            <Tooltip title="Перейти в админ-панель">
              <Button
                variant="contained"
                color="secondary"
                onClick={goToAdminPanel}
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  boxShadow: 3,
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}
              >
                Админ панель
              </Button>
            </Tooltip>
          )}

          <Box display="flex" alignItems="center">
            <Typography variant="body2" mr={1}>
              Темная тема
            </Typography>
            <Switch checked={darkMode} onChange={handleToggleDarkMode} />
          </Box>
        </Box>

        {/* Панель фильтров (Drawer справа) */}
        <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <Box p={3} width={isMobile ? '100vw' : 400} role="presentation">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">Фильтры</Typography>
              <IconButton onClick={() => setDrawerOpen(false)}>
                <ExpandLessIcon />
              </IconButton>
            </Box>

            {/* Чекбокс "Выбрать все" */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={isIndeterminate}
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleSelectAll();
                    } else {
                      handleDeselectAll();
                    }
                  }}
                />
              }
              label="Выбрать все"
            />

            {/* Кнопка сбросить фильтры */}
            <Box display="flex" justifyContent="center" mb={2}>
              <Button
                onClick={() => {
                  handleDeselectAll();
                }}
                variant="text"
                color="secondary"
                startIcon={<ClearAllIcon />}
              >
                Сбросить фильтры
              </Button>
            </Box>

            {/* Цвета */}
            <Box mb={2}>
              <Typography variant="body2" gutterBottom>
                Цвета
              </Typography>
              <Autocomplete
                multiple
                options={availableColors}
                getOptionLabel={(option) => option}
                value={selectedColors}
                onChange={(event, newValue) => {
                  setSelectedColors(newValue);
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      sx={{
                        width: 20,
                        height: 20,
                        backgroundColor: option,
                        borderRadius: '50%',
                        marginRight: 1,
                        border: '1px solid #ccc',
                      }}
                    />
                    {option}
                  </li>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                      avatar={
                        <Box
                          sx={{
                            width: 20,
                            height: 20,
                            backgroundColor: option,
                            borderRadius: '50%',
                            border: '1px solid #ccc',
                          }}
                        />
                      }
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Выберите цвета"
                  />
                )}
                size="small"
              />
            </Box>

            {/* Список проектов */}
            <StyledPaper>
              <Typography variant="subtitle1" gutterBottom>
                Проекты
              </Typography>
              {projects.map((project) => (
                <Accordion key={project.id} defaultExpanded={false} sx={{ mb: 1 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-project-${project.id}-content`}
                    id={`panel-project-${project.id}-header`}
                  >
                    {/* Чекбокс у проекта */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedProjects.includes(project.id)}
                          onChange={(e) => handleProjectCheckboxChange(e, project.id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                      label={project.title}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Доски проекта */}
                    <Box mb={2}>
                      <Typography variant="body2" gutterBottom>
                        Доски
                      </Typography>
                      <Autocomplete
                        multiple
                        options={boards[project.id] || []}
                        getOptionLabel={(option) => option.title}
                        value={
                          boards[project.id]
                            ? selectedBoards[project.id]
                                ?.map((id) =>
                                  (boards[project.id] || []).find((b) => b.id === id)
                                )
                                .filter(Boolean)
                            : []
                        }
                        onChange={(event, newValue) => {
                          setSelectedBoards((prevSelectedBoards) => ({
                            ...prevSelectedBoards,
                            [project.id]: newValue.map((b) => b.id),
                          }));
                        }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.title}
                              {...getTagProps({ index })}
                              key={option.id}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Выберите доски"
                          />
                        )}
                        size="small"
                      />
                    </Box>

                    {/* Колонки для каждой доски */}
                    {(boards[project.id] || []).map((board) => (
                      <Accordion key={board.id} defaultExpanded={false} sx={{ mb: 1 }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel-board-${board.id}-content`}
                          id={`panel-board-${board.id}-header`}
                        >
                          <Typography variant="body2">{board.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="caption" display="block" gutterBottom>
                            Колонки
                          </Typography>
                          <Autocomplete
                            multiple
                            options={columns[board.id] || []}
                            getOptionLabel={(option) => option.title}
                            value={
                              columns[board.id]
                                ? selectedColumns[board.id]
                                    ?.map((id) =>
                                      (columns[board.id] || []).find(
                                        (c) => c.id === id
                                      )
                                    )
                                    .filter(Boolean)
                                : []
                            }
                            onChange={(event, newValue) => {
                              setSelectedColumns((prevSelectedColumns) => ({
                                ...prevSelectedColumns,
                                [board.id]: newValue.map((c) => c.id),
                              }));
                            }}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Chip
                                  label={option.title}
                                  {...getTagProps({ index })}
                                  key={option.id}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Выберите колонки"
                              />
                            )}
                            size="small"
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </StyledPaper>

            {/* Применить фильтры */}
            <Button
              onClick={() => {
                loadMarkersWithFilters();
                setDrawerOpen(false);
              }}
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 2,
                borderRadius: 2,
                boxShadow: 3,
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
              startIcon={<FilterListIcon />}
              disabled={loading}
            >
              Применить фильтры
            </Button>

            {loading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            )}

            {/* Статистика */}
            <StyledPaper>
              <Typography variant="subtitle1" gutterBottom>
                Статистика
              </Typography>
              <Divider sx={{ mb: 1 }} />
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2">Всего объектов:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" fontWeight="bold">
                    {stats.total}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Задач "В работе":</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" fontWeight="bold">
                    {stats.inProgress}
                  </Typography>
                </Grid>
              </Grid>
            </StyledPaper>
          </Box>
        </Drawer>

        {/* Контейнер для карты */}
        <div
          ref={mapContainerRef}
          style={{
            height: '100vh',
            width: '100vw',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
          }}
        ></div>

        {/* Уведомления */}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>

        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setError(null)}
              severity="error"
              sx={{ width: '100%' }}
            >
              {error}
            </Alert>
          </Snackbar>
        )}

        {/* Drawer для списка объектов в кластере */}
        <Drawer
          anchor="left"
          open={clusterListOpen}
          onClose={() => setClusterListOpen(false)}
        >
          <Box
            p={3}
            width={isMobile ? '100vw' : 300}
            role="presentation"
            sx={{
              backgroundColor: 'background.paper',
              height: '100%',
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6">Список объектов в кластере</Typography>
              <IconButton onClick={() => setClusterListOpen(false)}>
                <ExpandLessIcon />
              </IconButton>
            </Box>

            {/* Список объектов */}
            <List>
              {selectedClusterObjects.map((obj, index) => (
                <ListItem key={index} divider>
                  <ListItemText
                    primary={`${index + 1}. ${obj.projectTitle} / ${obj.columnTitle} / ${obj.title}`}
                    secondary={obj.address || ''}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
};

export default MapPage;
